import { MapPolyline } from "@skydio/pbtypes/pbtypes/vehicle/skills/map_scene_pb";

import type { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import type { MapSceneState } from "@skydio/channels/src/map_scene_state_pb";
import type { LngLatAltTuple } from "@skydio/math";
import type { FlightPlayerState } from "../slice";

/**
 * Constants originating from `vehicle/skills/builtin_skills/surface_scan/constants.py`.
 *
 * TODO (Rene/Ayush): Determine a strategy to share these constants between vehicle and cloud,
 * ensuring consistent usage across both environments.
 */
const MAP_PATH_CURRENT_COMPONENT_POLYLINE_COLOR = MapPolyline.LineColor.BLUE_400;
const MAP_PATH_PROGRESS_POLYLINE_COLOR = MapPolyline.LineColor.GREEN;

/**
 * Updates the flight player state with trajectory information derived from a map scene message.
 *
 * This reducer inspects the `objectsList` of the incoming `mapSceneStateMessage` payload to find
 * two sets of polylines based on their colors:
 *
 * - **Progress Polyline** (`MAP_PATH_PROGRESS_POLYLINE_COLOR`): Represents the already-completed path.
 *   Its waypoints are extracted into `state.mapCaptureCompletedTrajectory`.
 * - **Current Component Polyline** (`MAP_PATH_CURRENT_COMPONENT_POLYLINE_COLOR`): Represents the next leg of the path.
 *   Its waypoints are extracted into `state.mapCaptureTrajectory`.
 *
 * The reducer then stores these waypoint lists in the corresponding fields of the FlightPlayer state.
 *
 * @param {FlightPlayerState} state - The current slice of the Redux store for the flight player.
 * @param {PayloadAction<MapSceneState.AsObject>} action - Redux action containing the map scene message.
 *   - `mapSceneStateMessage`: The deserialized object of type `MapSceneState.AsObject`.
 */
export const handleMapCaptureMessage: CaseReducer<
  FlightPlayerState,
  PayloadAction<MapSceneState.AsObject>
> = (state, { payload: mapSceneStateMessage }) => {
  const extractTrajectory = (
    polyline: NonNullable<(typeof mapSceneStateMessage.objectsList)[number]["polyline"]>
  ) =>
    polyline.waypointsList.reduce<LngLatAltTuple[]>((acc, { waypoint }) => {
      if (waypoint?.xy?.y !== undefined && waypoint.xy?.x !== undefined) {
        acc.push([waypoint.xy.y, waypoint.xy.x] as LngLatAltTuple);
      }
      return acc;
    }, []);

  let trajectory: LngLatAltTuple[] = [];
  let completedTrajectory: LngLatAltTuple[] = [];

  for (const { polyline } of mapSceneStateMessage.objectsList) {
    if (!polyline) continue;
    if (polyline.lineColor === MAP_PATH_PROGRESS_POLYLINE_COLOR) {
      completedTrajectory = extractTrajectory(polyline);
    } else if (polyline.lineColor === MAP_PATH_CURRENT_COMPONENT_POLYLINE_COLOR) {
      trajectory = extractTrajectory(polyline);
    }
  }

  state.mapCaptureTrajectory = trajectory;
  state.mapCaptureCompletedTrajectory = completedTrajectory;
};
