// source: pbtypes/vehicle/skills/map_scene.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_vehicle_skills_waypoints_pb = require('../../../pbtypes/vehicle/skills/waypoints_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_waypoints_pb);
goog.exportSymbol('proto.skills.MapCircle', null, global);
goog.exportSymbol('proto.skills.MapMenuOption', null, global);
goog.exportSymbol('proto.skills.MapObject', null, global);
goog.exportSymbol('proto.skills.MapObject.ObjectCase', null, global);
goog.exportSymbol('proto.skills.MapPolygon', null, global);
goog.exportSymbol('proto.skills.MapPolyline', null, global);
goog.exportSymbol('proto.skills.MapPolyline.LineColor', null, global);
goog.exportSymbol('proto.skills.MapPolylineUpdate', null, global);
goog.exportSymbol('proto.skills.MapPress', null, global);
goog.exportSymbol('proto.skills.MapSceneAck', null, global);
goog.exportSymbol('proto.skills.MapSceneAction', null, global);
goog.exportSymbol('proto.skills.MapSceneAction.ActionType', null, global);
goog.exportSymbol('proto.skills.MapSceneState', null, global);
goog.exportSymbol('proto.skills.MapWaypoint', null, global);
goog.exportSymbol('proto.skills.MapZoomInsets', null, global);
goog.exportSymbol('proto.skills.MapZoomRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapWaypoint.displayName = 'proto.skills.MapWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapPolygon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.MapPolygon.repeatedFields_, null);
};
goog.inherits(proto.skills.MapPolygon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapPolygon.displayName = 'proto.skills.MapPolygon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapPolyline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.MapPolyline.repeatedFields_, null);
};
goog.inherits(proto.skills.MapPolyline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapPolyline.displayName = 'proto.skills.MapPolyline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapCircle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapCircle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapCircle.displayName = 'proto.skills.MapCircle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapPress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapPress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapPress.displayName = 'proto.skills.MapPress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapPolylineUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapPolylineUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapPolylineUpdate.displayName = 'proto.skills.MapPolylineUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapMenuOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapMenuOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapMenuOption.displayName = 'proto.skills.MapMenuOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.MapObject.oneofGroups_);
};
goog.inherits(proto.skills.MapObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapObject.displayName = 'proto.skills.MapObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapSceneAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapSceneAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapSceneAction.displayName = 'proto.skills.MapSceneAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapSceneAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapSceneAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapSceneAck.displayName = 'proto.skills.MapSceneAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapZoomInsets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapZoomInsets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapZoomInsets.displayName = 'proto.skills.MapZoomInsets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapZoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MapZoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapZoomRequest.displayName = 'proto.skills.MapZoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MapSceneState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.MapSceneState.repeatedFields_, null);
};
goog.inherits(proto.skills.MapSceneState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MapSceneState.displayName = 'proto.skills.MapSceneState';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, 0),
waypoint: (f = msg.getWaypoint()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
isDraggable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapWaypoint}
 */
proto.skills.MapWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapWaypoint;
  return proto.skills.MapWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapWaypoint}
 */
proto.skills.MapWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDraggable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getIsDraggable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.skills.MapWaypoint.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapWaypoint} returns this
 */
proto.skills.MapWaypoint.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Waypoint waypoint = 2;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.MapWaypoint.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 2));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.MapWaypoint} returns this
*/
proto.skills.MapWaypoint.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapWaypoint} returns this
 */
proto.skills.MapWaypoint.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapWaypoint.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_draggable = 3;
 * @return {boolean}
 */
proto.skills.MapWaypoint.prototype.getIsDraggable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapWaypoint} returns this
 */
proto.skills.MapWaypoint.prototype.setIsDraggable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.MapPolygon.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapPolygon.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapPolygon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapPolygon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapPolygon.toObject = function(includeInstance, msg) {
  var f, obj = {
waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto.skills.MapWaypoint.toObject, includeInstance),
allowDynamicSize: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
isLoop: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
strokeWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapPolygon}
 */
proto.skills.MapPolygon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapPolygon;
  return proto.skills.MapPolygon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapPolygon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapPolygon}
 */
proto.skills.MapPolygon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.MapWaypoint;
      reader.readMessage(value,proto.skills.MapWaypoint.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDynamicSize(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLoop(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStrokeWidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapPolygon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapPolygon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapPolygon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapPolygon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.skills.MapWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getAllowDynamicSize();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsLoop();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStrokeWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * repeated MapWaypoint waypoints = 1;
 * @return {!Array<!proto.skills.MapWaypoint>}
 */
proto.skills.MapPolygon.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.skills.MapWaypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.MapWaypoint, 1));
};


/**
 * @param {!Array<!proto.skills.MapWaypoint>} value
 * @return {!proto.skills.MapPolygon} returns this
*/
proto.skills.MapPolygon.prototype.setWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skills.MapWaypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.MapWaypoint}
 */
proto.skills.MapPolygon.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skills.MapWaypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.MapPolygon} returns this
 */
proto.skills.MapPolygon.prototype.clearWaypointsList = function() {
  return this.setWaypointsList([]);
};


/**
 * optional bool allow_dynamic_size = 2;
 * @return {boolean}
 */
proto.skills.MapPolygon.prototype.getAllowDynamicSize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapPolygon} returns this
 */
proto.skills.MapPolygon.prototype.setAllowDynamicSize = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_loop = 3;
 * @return {boolean}
 */
proto.skills.MapPolygon.prototype.getIsLoop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapPolygon} returns this
 */
proto.skills.MapPolygon.prototype.setIsLoop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float stroke_width = 4;
 * @return {number}
 */
proto.skills.MapPolygon.prototype.getStrokeWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapPolygon} returns this
 */
proto.skills.MapPolygon.prototype.setStrokeWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.MapPolyline.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapPolyline.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapPolyline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapPolyline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapPolyline.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, 0),
waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto.skills.MapWaypoint.toObject, includeInstance),
lineColor: jspb.Message.getFieldWithDefault(msg, 3, 0),
strokeWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
allowRotate: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapPolyline}
 */
proto.skills.MapPolyline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapPolyline;
  return proto.skills.MapPolyline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapPolyline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapPolyline}
 */
proto.skills.MapPolyline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.skills.MapWaypoint;
      reader.readMessage(value,proto.skills.MapWaypoint.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    case 3:
      var value = /** @type {!proto.skills.MapPolyline.LineColor} */ (reader.readEnum());
      msg.setLineColor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStrokeWidth(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowRotate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapPolyline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapPolyline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapPolyline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapPolyline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.skills.MapWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getLineColor();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStrokeWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAllowRotate();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.MapPolyline.LineColor = {
  WHITE: 0,
  BLUE: 1,
  GREEN: 2,
  BLUE_400: 3,
  BLUE_700: 4
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.skills.MapPolyline.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapPolyline} returns this
 */
proto.skills.MapPolyline.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated MapWaypoint waypoints = 2;
 * @return {!Array<!proto.skills.MapWaypoint>}
 */
proto.skills.MapPolyline.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.skills.MapWaypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.MapWaypoint, 2));
};


/**
 * @param {!Array<!proto.skills.MapWaypoint>} value
 * @return {!proto.skills.MapPolyline} returns this
*/
proto.skills.MapPolyline.prototype.setWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.skills.MapWaypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.MapWaypoint}
 */
proto.skills.MapPolyline.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.skills.MapWaypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.MapPolyline} returns this
 */
proto.skills.MapPolyline.prototype.clearWaypointsList = function() {
  return this.setWaypointsList([]);
};


/**
 * optional LineColor line_color = 3;
 * @return {!proto.skills.MapPolyline.LineColor}
 */
proto.skills.MapPolyline.prototype.getLineColor = function() {
  return /** @type {!proto.skills.MapPolyline.LineColor} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skills.MapPolyline.LineColor} value
 * @return {!proto.skills.MapPolyline} returns this
 */
proto.skills.MapPolyline.prototype.setLineColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float stroke_width = 4;
 * @return {number}
 */
proto.skills.MapPolyline.prototype.getStrokeWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapPolyline} returns this
 */
proto.skills.MapPolyline.prototype.setStrokeWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool allow_rotate = 5;
 * @return {boolean}
 */
proto.skills.MapPolyline.prototype.getAllowRotate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapPolyline} returns this
 */
proto.skills.MapPolyline.prototype.setAllowRotate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapCircle.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapCircle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapCircle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapCircle.toObject = function(includeInstance, msg) {
  var f, obj = {
center: (f = msg.getCenter()) && proto.skills.MapWaypoint.toObject(includeInstance, f),
isResizable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
minRadius: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
maxRadius: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapCircle}
 */
proto.skills.MapCircle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapCircle;
  return proto.skills.MapCircle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapCircle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapCircle}
 */
proto.skills.MapCircle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.MapWaypoint;
      reader.readMessage(value,proto.skills.MapWaypoint.deserializeBinaryFromReader);
      msg.setCenter(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResizable(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRadius(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinRadius(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxRadius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapCircle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapCircle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapCircle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapCircle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.MapWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getIsResizable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMinRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMaxRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional MapWaypoint center = 1;
 * @return {?proto.skills.MapWaypoint}
 */
proto.skills.MapCircle.prototype.getCenter = function() {
  return /** @type{?proto.skills.MapWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapWaypoint, 1));
};


/**
 * @param {?proto.skills.MapWaypoint|undefined} value
 * @return {!proto.skills.MapCircle} returns this
*/
proto.skills.MapCircle.prototype.setCenter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapCircle} returns this
 */
proto.skills.MapCircle.prototype.clearCenter = function() {
  return this.setCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapCircle.prototype.hasCenter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_resizable = 3;
 * @return {boolean}
 */
proto.skills.MapCircle.prototype.getIsResizable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapCircle} returns this
 */
proto.skills.MapCircle.prototype.setIsResizable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float radius = 2;
 * @return {number}
 */
proto.skills.MapCircle.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapCircle} returns this
 */
proto.skills.MapCircle.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float min_radius = 4;
 * @return {number}
 */
proto.skills.MapCircle.prototype.getMinRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapCircle} returns this
 */
proto.skills.MapCircle.prototype.setMinRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float max_radius = 5;
 * @return {number}
 */
proto.skills.MapCircle.prototype.getMaxRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapCircle} returns this
 */
proto.skills.MapCircle.prototype.setMaxRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapPress.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapPress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapPress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapPress.toObject = function(includeInstance, msg) {
  var f, obj = {
latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
action: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapPress}
 */
proto.skills.MapPress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapPress;
  return proto.skills.MapPress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapPress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapPress}
 */
proto.skills.MapPress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapPress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapPress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapPress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapPress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.skills.MapPress.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapPress} returns this
 */
proto.skills.MapPress.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.skills.MapPress.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapPress} returns this
 */
proto.skills.MapPress.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string action = 3;
 * @return {string}
 */
proto.skills.MapPress.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MapPress} returns this
 */
proto.skills.MapPress.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapPolylineUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapPolylineUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapPolylineUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapPolylineUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
targetPolylineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
angle: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapPolylineUpdate}
 */
proto.skills.MapPolylineUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapPolylineUpdate;
  return proto.skills.MapPolylineUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapPolylineUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapPolylineUpdate}
 */
proto.skills.MapPolylineUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetPolylineId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapPolylineUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapPolylineUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapPolylineUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapPolylineUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetPolylineId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 target_polyline_id = 1;
 * @return {number}
 */
proto.skills.MapPolylineUpdate.prototype.getTargetPolylineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapPolylineUpdate} returns this
 */
proto.skills.MapPolylineUpdate.prototype.setTargetPolylineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 angle = 2;
 * @return {number}
 */
proto.skills.MapPolylineUpdate.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapPolylineUpdate} returns this
 */
proto.skills.MapPolylineUpdate.prototype.setAngle = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapMenuOption.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapMenuOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapMenuOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapMenuOption.toObject = function(includeInstance, msg) {
  var f, obj = {
title: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapMenuOption}
 */
proto.skills.MapMenuOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapMenuOption;
  return proto.skills.MapMenuOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapMenuOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapMenuOption}
 */
proto.skills.MapMenuOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapMenuOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapMenuOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapMenuOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapMenuOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.skills.MapMenuOption.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MapMenuOption} returns this
 */
proto.skills.MapMenuOption.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.MapObject.oneofGroups_ = [[2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.skills.MapObject.ObjectCase = {
  OBJECT_NOT_SET: 0,
  WAYPOINT: 2,
  POLYGON: 3,
  CIRCLE: 4,
  MAP_PRESS: 5,
  POLYLINE: 6,
  POLYLINE_UPDATE: 7
};

/**
 * @return {proto.skills.MapObject.ObjectCase}
 */
proto.skills.MapObject.prototype.getObjectCase = function() {
  return /** @type {proto.skills.MapObject.ObjectCase} */(jspb.Message.computeOneofCase(this, proto.skills.MapObject.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapObject.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapObject.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, 0),
waypoint: (f = msg.getWaypoint()) && proto.skills.MapWaypoint.toObject(includeInstance, f),
polygon: (f = msg.getPolygon()) && proto.skills.MapPolygon.toObject(includeInstance, f),
circle: (f = msg.getCircle()) && proto.skills.MapCircle.toObject(includeInstance, f),
mapPress: (f = msg.getMapPress()) && proto.skills.MapPress.toObject(includeInstance, f),
polyline: (f = msg.getPolyline()) && proto.skills.MapPolyline.toObject(includeInstance, f),
polylineUpdate: (f = msg.getPolylineUpdate()) && proto.skills.MapPolylineUpdate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapObject}
 */
proto.skills.MapObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapObject;
  return proto.skills.MapObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapObject}
 */
proto.skills.MapObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.skills.MapWaypoint;
      reader.readMessage(value,proto.skills.MapWaypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 3:
      var value = new proto.skills.MapPolygon;
      reader.readMessage(value,proto.skills.MapPolygon.deserializeBinaryFromReader);
      msg.setPolygon(value);
      break;
    case 4:
      var value = new proto.skills.MapCircle;
      reader.readMessage(value,proto.skills.MapCircle.deserializeBinaryFromReader);
      msg.setCircle(value);
      break;
    case 5:
      var value = new proto.skills.MapPress;
      reader.readMessage(value,proto.skills.MapPress.deserializeBinaryFromReader);
      msg.setMapPress(value);
      break;
    case 6:
      var value = new proto.skills.MapPolyline;
      reader.readMessage(value,proto.skills.MapPolyline.deserializeBinaryFromReader);
      msg.setPolyline(value);
      break;
    case 7:
      var value = new proto.skills.MapPolylineUpdate;
      reader.readMessage(value,proto.skills.MapPolylineUpdate.deserializeBinaryFromReader);
      msg.setPolylineUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.MapWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getPolygon();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.MapPolygon.serializeBinaryToWriter
    );
  }
  f = message.getCircle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.MapCircle.serializeBinaryToWriter
    );
  }
  f = message.getMapPress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skills.MapPress.serializeBinaryToWriter
    );
  }
  f = message.getPolyline();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.MapPolyline.serializeBinaryToWriter
    );
  }
  f = message.getPolylineUpdate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skills.MapPolylineUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.skills.MapObject.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapObject} returns this
 */
proto.skills.MapObject.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional MapWaypoint waypoint = 2;
 * @return {?proto.skills.MapWaypoint}
 */
proto.skills.MapObject.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.MapWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapWaypoint, 2));
};


/**
 * @param {?proto.skills.MapWaypoint|undefined} value
 * @return {!proto.skills.MapObject} returns this
*/
proto.skills.MapObject.prototype.setWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.skills.MapObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapObject} returns this
 */
proto.skills.MapObject.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapObject.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MapPolygon polygon = 3;
 * @return {?proto.skills.MapPolygon}
 */
proto.skills.MapObject.prototype.getPolygon = function() {
  return /** @type{?proto.skills.MapPolygon} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapPolygon, 3));
};


/**
 * @param {?proto.skills.MapPolygon|undefined} value
 * @return {!proto.skills.MapObject} returns this
*/
proto.skills.MapObject.prototype.setPolygon = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.skills.MapObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapObject} returns this
 */
proto.skills.MapObject.prototype.clearPolygon = function() {
  return this.setPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapObject.prototype.hasPolygon = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MapCircle circle = 4;
 * @return {?proto.skills.MapCircle}
 */
proto.skills.MapObject.prototype.getCircle = function() {
  return /** @type{?proto.skills.MapCircle} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapCircle, 4));
};


/**
 * @param {?proto.skills.MapCircle|undefined} value
 * @return {!proto.skills.MapObject} returns this
*/
proto.skills.MapObject.prototype.setCircle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.skills.MapObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapObject} returns this
 */
proto.skills.MapObject.prototype.clearCircle = function() {
  return this.setCircle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapObject.prototype.hasCircle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MapPress map_press = 5;
 * @return {?proto.skills.MapPress}
 */
proto.skills.MapObject.prototype.getMapPress = function() {
  return /** @type{?proto.skills.MapPress} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapPress, 5));
};


/**
 * @param {?proto.skills.MapPress|undefined} value
 * @return {!proto.skills.MapObject} returns this
*/
proto.skills.MapObject.prototype.setMapPress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.skills.MapObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapObject} returns this
 */
proto.skills.MapObject.prototype.clearMapPress = function() {
  return this.setMapPress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapObject.prototype.hasMapPress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MapPolyline polyline = 6;
 * @return {?proto.skills.MapPolyline}
 */
proto.skills.MapObject.prototype.getPolyline = function() {
  return /** @type{?proto.skills.MapPolyline} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapPolyline, 6));
};


/**
 * @param {?proto.skills.MapPolyline|undefined} value
 * @return {!proto.skills.MapObject} returns this
*/
proto.skills.MapObject.prototype.setPolyline = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.skills.MapObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapObject} returns this
 */
proto.skills.MapObject.prototype.clearPolyline = function() {
  return this.setPolyline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapObject.prototype.hasPolyline = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MapPolylineUpdate polyline_update = 7;
 * @return {?proto.skills.MapPolylineUpdate}
 */
proto.skills.MapObject.prototype.getPolylineUpdate = function() {
  return /** @type{?proto.skills.MapPolylineUpdate} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapPolylineUpdate, 7));
};


/**
 * @param {?proto.skills.MapPolylineUpdate|undefined} value
 * @return {!proto.skills.MapObject} returns this
*/
proto.skills.MapObject.prototype.setPolylineUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.skills.MapObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapObject} returns this
 */
proto.skills.MapObject.prototype.clearPolylineUpdate = function() {
  return this.setPolylineUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapObject.prototype.hasPolylineUpdate = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapSceneAction.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapSceneAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapSceneAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapSceneAction.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
actionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
target: (f = msg.getTarget()) && proto.skills.MapObject.toObject(includeInstance, f),
actionNonce: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapSceneAction}
 */
proto.skills.MapSceneAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapSceneAction;
  return proto.skills.MapSceneAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapSceneAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapSceneAction}
 */
proto.skills.MapSceneAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.skills.MapSceneAction.ActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 3:
      var value = new proto.skills.MapObject;
      reader.readMessage(value,proto.skills.MapObject.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActionNonce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapSceneAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapSceneAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapSceneAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapSceneAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.MapObject.serializeBinaryToWriter
    );
  }
  f = message.getActionNonce();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.MapSceneAction.ActionType = {
  INVALID: 0,
  EDIT: 1,
  MAP_PRESS: 2,
  ADD: 3,
  DELETE: 4
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.MapSceneAction.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapSceneAction} returns this
 */
proto.skills.MapSceneAction.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ActionType action_type = 2;
 * @return {!proto.skills.MapSceneAction.ActionType}
 */
proto.skills.MapSceneAction.prototype.getActionType = function() {
  return /** @type {!proto.skills.MapSceneAction.ActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skills.MapSceneAction.ActionType} value
 * @return {!proto.skills.MapSceneAction} returns this
 */
proto.skills.MapSceneAction.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MapObject target = 3;
 * @return {?proto.skills.MapObject}
 */
proto.skills.MapSceneAction.prototype.getTarget = function() {
  return /** @type{?proto.skills.MapObject} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapObject, 3));
};


/**
 * @param {?proto.skills.MapObject|undefined} value
 * @return {!proto.skills.MapSceneAction} returns this
*/
proto.skills.MapSceneAction.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapSceneAction} returns this
 */
proto.skills.MapSceneAction.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapSceneAction.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 action_nonce = 5;
 * @return {number}
 */
proto.skills.MapSceneAction.prototype.getActionNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapSceneAction} returns this
 */
proto.skills.MapSceneAction.prototype.setActionNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapSceneAck.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapSceneAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapSceneAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapSceneAck.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
actionNonce: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapSceneAck}
 */
proto.skills.MapSceneAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapSceneAck;
  return proto.skills.MapSceneAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapSceneAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapSceneAck}
 */
proto.skills.MapSceneAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActionNonce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapSceneAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapSceneAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapSceneAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapSceneAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActionNonce();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.MapSceneAck.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapSceneAck} returns this
 */
proto.skills.MapSceneAck.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 action_nonce = 5;
 * @return {number}
 */
proto.skills.MapSceneAck.prototype.getActionNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapSceneAck} returns this
 */
proto.skills.MapSceneAck.prototype.setActionNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapZoomInsets.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapZoomInsets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapZoomInsets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapZoomInsets.toObject = function(includeInstance, msg) {
  var f, obj = {
topInset: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
leftInset: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
bottomInset: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
rightInset: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapZoomInsets}
 */
proto.skills.MapZoomInsets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapZoomInsets;
  return proto.skills.MapZoomInsets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapZoomInsets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapZoomInsets}
 */
proto.skills.MapZoomInsets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTopInset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLeftInset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBottomInset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRightInset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapZoomInsets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapZoomInsets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapZoomInsets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapZoomInsets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopInset();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLeftInset();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBottomInset();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getRightInset();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float top_inset = 1;
 * @return {number}
 */
proto.skills.MapZoomInsets.prototype.getTopInset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapZoomInsets} returns this
 */
proto.skills.MapZoomInsets.prototype.setTopInset = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float left_inset = 2;
 * @return {number}
 */
proto.skills.MapZoomInsets.prototype.getLeftInset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapZoomInsets} returns this
 */
proto.skills.MapZoomInsets.prototype.setLeftInset = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float bottom_inset = 3;
 * @return {number}
 */
proto.skills.MapZoomInsets.prototype.getBottomInset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapZoomInsets} returns this
 */
proto.skills.MapZoomInsets.prototype.setBottomInset = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float right_inset = 4;
 * @return {number}
 */
proto.skills.MapZoomInsets.prototype.getRightInset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapZoomInsets} returns this
 */
proto.skills.MapZoomInsets.prototype.setRightInset = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapZoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapZoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapZoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapZoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
enable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
northWestBound: (f = msg.getNorthWestBound()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
southEastBound: (f = msg.getSouthEastBound()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
insets: (f = msg.getInsets()) && proto.skills.MapZoomInsets.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapZoomRequest}
 */
proto.skills.MapZoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapZoomRequest;
  return proto.skills.MapZoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapZoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapZoomRequest}
 */
proto.skills.MapZoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setNorthWestBound(value);
      break;
    case 3:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setSouthEastBound(value);
      break;
    case 4:
      var value = new proto.skills.MapZoomInsets;
      reader.readMessage(value,proto.skills.MapZoomInsets.deserializeBinaryFromReader);
      msg.setInsets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapZoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapZoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapZoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapZoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getNorthWestBound();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getSouthEastBound();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getInsets();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.MapZoomInsets.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool enable = 1;
 * @return {boolean}
 */
proto.skills.MapZoomRequest.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapZoomRequest} returns this
 */
proto.skills.MapZoomRequest.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Waypoint north_west_bound = 2;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.MapZoomRequest.prototype.getNorthWestBound = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 2));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.MapZoomRequest} returns this
*/
proto.skills.MapZoomRequest.prototype.setNorthWestBound = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapZoomRequest} returns this
 */
proto.skills.MapZoomRequest.prototype.clearNorthWestBound = function() {
  return this.setNorthWestBound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapZoomRequest.prototype.hasNorthWestBound = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Waypoint south_east_bound = 3;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.MapZoomRequest.prototype.getSouthEastBound = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 3));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.MapZoomRequest} returns this
*/
proto.skills.MapZoomRequest.prototype.setSouthEastBound = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapZoomRequest} returns this
 */
proto.skills.MapZoomRequest.prototype.clearSouthEastBound = function() {
  return this.setSouthEastBound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapZoomRequest.prototype.hasSouthEastBound = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MapZoomInsets insets = 4;
 * @return {?proto.skills.MapZoomInsets}
 */
proto.skills.MapZoomRequest.prototype.getInsets = function() {
  return /** @type{?proto.skills.MapZoomInsets} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapZoomInsets, 4));
};


/**
 * @param {?proto.skills.MapZoomInsets|undefined} value
 * @return {!proto.skills.MapZoomRequest} returns this
*/
proto.skills.MapZoomRequest.prototype.setInsets = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapZoomRequest} returns this
 */
proto.skills.MapZoomRequest.prototype.clearInsets = function() {
  return this.setInsets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapZoomRequest.prototype.hasInsets = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.MapSceneState.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MapSceneState.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MapSceneState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MapSceneState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapSceneState.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    proto.skills.MapObject.toObject, includeInstance),
mapMenuOptionsList: jspb.Message.toObjectList(msg.getMapMenuOptionsList(),
    proto.skills.MapMenuOption.toObject, includeInstance),
actionNonce: jspb.Message.getFieldWithDefault(msg, 3, 0),
switchToMap: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
switchToFeed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
setGpsPosition: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
mapZoomRequest: (f = msg.getMapZoomRequest()) && proto.skills.MapZoomRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MapSceneState}
 */
proto.skills.MapSceneState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MapSceneState;
  return proto.skills.MapSceneState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MapSceneState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MapSceneState}
 */
proto.skills.MapSceneState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new proto.skills.MapObject;
      reader.readMessage(value,proto.skills.MapObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    case 4:
      var value = new proto.skills.MapMenuOption;
      reader.readMessage(value,proto.skills.MapMenuOption.deserializeBinaryFromReader);
      msg.addMapMenuOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActionNonce(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwitchToMap(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwitchToFeed(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSetGpsPosition(value);
      break;
    case 7:
      var value = new proto.skills.MapZoomRequest;
      reader.readMessage(value,proto.skills.MapZoomRequest.deserializeBinaryFromReader);
      msg.setMapZoomRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MapSceneState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MapSceneState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MapSceneState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MapSceneState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.skills.MapObject.serializeBinaryToWriter
    );
  }
  f = message.getMapMenuOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.skills.MapMenuOption.serializeBinaryToWriter
    );
  }
  f = message.getActionNonce();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSwitchToMap();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSwitchToFeed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSetGpsPosition();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMapZoomRequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skills.MapZoomRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.MapSceneState.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapSceneState} returns this
 */
proto.skills.MapSceneState.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated MapObject objects = 2;
 * @return {!Array<!proto.skills.MapObject>}
 */
proto.skills.MapSceneState.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.skills.MapObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.MapObject, 2));
};


/**
 * @param {!Array<!proto.skills.MapObject>} value
 * @return {!proto.skills.MapSceneState} returns this
*/
proto.skills.MapSceneState.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.skills.MapObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.MapObject}
 */
proto.skills.MapSceneState.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.skills.MapObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.MapSceneState} returns this
 */
proto.skills.MapSceneState.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};


/**
 * repeated MapMenuOption map_menu_options = 4;
 * @return {!Array<!proto.skills.MapMenuOption>}
 */
proto.skills.MapSceneState.prototype.getMapMenuOptionsList = function() {
  return /** @type{!Array<!proto.skills.MapMenuOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.MapMenuOption, 4));
};


/**
 * @param {!Array<!proto.skills.MapMenuOption>} value
 * @return {!proto.skills.MapSceneState} returns this
*/
proto.skills.MapSceneState.prototype.setMapMenuOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.skills.MapMenuOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.MapMenuOption}
 */
proto.skills.MapSceneState.prototype.addMapMenuOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.skills.MapMenuOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.MapSceneState} returns this
 */
proto.skills.MapSceneState.prototype.clearMapMenuOptionsList = function() {
  return this.setMapMenuOptionsList([]);
};


/**
 * optional int64 action_nonce = 3;
 * @return {number}
 */
proto.skills.MapSceneState.prototype.getActionNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MapSceneState} returns this
 */
proto.skills.MapSceneState.prototype.setActionNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool switch_to_map = 5;
 * @return {boolean}
 */
proto.skills.MapSceneState.prototype.getSwitchToMap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapSceneState} returns this
 */
proto.skills.MapSceneState.prototype.setSwitchToMap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool switch_to_feed = 6;
 * @return {boolean}
 */
proto.skills.MapSceneState.prototype.getSwitchToFeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapSceneState} returns this
 */
proto.skills.MapSceneState.prototype.setSwitchToFeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool set_gps_position = 8;
 * @return {boolean}
 */
proto.skills.MapSceneState.prototype.getSetGpsPosition = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MapSceneState} returns this
 */
proto.skills.MapSceneState.prototype.setSetGpsPosition = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional MapZoomRequest map_zoom_request = 7;
 * @return {?proto.skills.MapZoomRequest}
 */
proto.skills.MapSceneState.prototype.getMapZoomRequest = function() {
  return /** @type{?proto.skills.MapZoomRequest} */ (
    jspb.Message.getWrapperField(this, proto.skills.MapZoomRequest, 7));
};


/**
 * @param {?proto.skills.MapZoomRequest|undefined} value
 * @return {!proto.skills.MapSceneState} returns this
*/
proto.skills.MapSceneState.prototype.setMapZoomRequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MapSceneState} returns this
 */
proto.skills.MapSceneState.prototype.clearMapZoomRequest = function() {
  return this.setMapZoomRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MapSceneState.prototype.hasMapZoomRequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.skills);
