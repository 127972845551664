import { createSlice } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";
import { uniq } from "lodash";

import { user_camera_mode_t } from "@skydio/lcm/types/user_camera/user_camera_mode_t";
import { feetToMeters, nauticalMilesToMeters } from "@skydio/math";

import { getNextMapStyle, MapStyle } from "utils/map";

import { handleAdsbTrafficMessage, handlePingStationStatusMessage } from "./adsb";
import { getLocalAirTraffic } from "./adsb/getLocalAirTraffic";
import { handleMapCaptureMessage } from "./map_capture";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { LocationChangeAction } from "connected-react-router";
import type { SyntheticEvent } from "react";
import type { Vector3 } from "three";
import type { AdsbVehicle } from "@skydio/channels/src/adsb_vehicle_pb";
import type { GimbalNavTransform } from "@skydio/channels/src/gimbal_nav_transform_pb";
import type { GpsWaypointStatus } from "@skydio/channels/src/gps_waypoint_status_pb";
import type { PingStationStatus } from "@skydio/channels/src/ping_station_status_pb";
import type { CameraStatus as UserCameraStatusNG } from "@skydio/channels/src/user_camera_status_ng_pb";
import type { DroneAndCameraPose } from "@skydio/channels/src/vehicle_and_camera_pose_lite_pb";
import type { BatteryPrediction } from "@skydio/channels/src/vehicle_battery_prediction_pb";
import type { pose_state_t } from "@skydio/channels/src/vehicle_pose";
import type { RtkGpsMinimalStatus } from "@skydio/channels/src/vehicle_rtk_gps_minimal_status_pb";
import type { GPSPoint, LngLatAltTuple, Milliseconds } from "@skydio/math";
import type { GpsWaypoint } from "@skydio/pbtypes/pbtypes/gen/skills/gps_waypoint_pb";
import type { VehicleStatus } from "@skydio/pbtypes/pbtypes/vehicle/ambassador/ambassador_pb";
import type { AnimationTracks } from "@skydio/shared_ui/three/animation/useAnimationKeyframe";
import type { ViewportProps } from "components/common/map/DynamicMap";

export type TrajectoryType = "gps" | "nav";

export type PaneType = "video" | "map";

export type BaseTelemetryType = { utime: number };

export type FlightInfo = {
  flightId: string;
  hasTelemetry: boolean;
  /** In microseconds */
  takeoffUtime: number;
};
export interface TelemetryData<T extends BaseTelemetryType> {
  current?: T;
  all: T[];
}

// NOTE(jesse): immer has issues with storing raw LcmMsg objects so we're just storing the values
// we need in plain objects
export type PlainPoseState = {
  utime: number;
  velocity_global: pose_state_t["velocity_global"]["data"];
  position: pose_state_t["position"]["data"];
  orientation: pose_state_t["orientation"]["xyzw"];
};
// export type UserCameraStatus = Pick<user_camera_status_t, "device_mode" | "utime">;
export type DeviceMode = "other" | "preview" | "recording";
export type UserCameraStatus = {
  utime: number;
  device_mode: DeviceMode;
};

// Time interval for which local air traffic won´t be updated when new drone telemetry data arrives
const AIR_TRAFFIC_MIN_UPDATE_TIME: Milliseconds = 1000;

/**
 * Maps user_camera_mode_t to our custom type to circumvent issues with
 * using `user_camera_mode_t` directly in the store due to immer.
 */
export const userCameraModeToDeviceMode = (mode: user_camera_mode_t): DeviceMode => {
  switch (mode.value) {
    case user_camera_mode_t.RECORDING.value:
      return "recording";
    case user_camera_mode_t.PREVIEW.value:
      return "preview";
    default:
      return "other";
  }
};

export type CameraDollyFollowData = {
  targetDisplacement: Vector3;
  startDisplacement: Vector3;
  startElapsedTime: number;
};
export type AirTrafficItemState = AdsbVehicle.AsObject & { receiveTime: number };
export type LocalAirTrafficItemState = {
  altitudeMSL: number;
  relativeAltitude: number;
  heading: number;
  emitterType: number;
  position: GPSPoint;
  distance: number;
  alert: boolean;
  icaoAddress: number;
  horizontalVelocity: number;
  verticalVelocity: number;
};
export type AdsbStationState = PingStationStatus.AsObject & { receiveTime: number };
export type AirTrafficDisplaySettings = {
  filter: { radius: number; altitude: number };
  alert: { radius: number; altitude: number };
};

export interface FlightPlayerState {
  playing: boolean;
  playbackTime: number;
  seekTime: number;
  hasSeeked: boolean;
  playbackRate: number;
  videoError?: Event;
  muted: boolean;
  fullscreenState: {
    fullscreenTarget: string | null;
    isFullscreen: boolean;
    fullscreenTargetPriority: number;
  };
  panes: PaneType[] | null;
  duration: number;
  mapViewport?: ViewportProps;
  mapStyle: MapStyle;
  telemetry: {
    pose: TelemetryData<PlainPoseState>;
    battery: TelemetryData<BatteryPrediction.AsObject>;
    vehicleStatus: TelemetryData<VehicleStatus.AsObject>;
    gps_data: TelemetryData<GpsWaypointStatus.AsObject>;
    gimbalNavTransform: TelemetryData<GimbalNavTransform.AsObject>;
    userCameraStatus: TelemetryData<UserCameraStatus>;
    userCameraStatusNG: TelemetryData<UserCameraStatusNG.AsObject>;
    vehicleAndCameraPoseLite: TelemetryData<DroneAndCameraPose.AsObject>;
    rtkGpsData: TelemetryData<RtkGpsMinimalStatus.AsObject>;
  };
  currentPathname?: string;
  trajectoryTypes: TrajectoryType[];
  recordingStartTime: number;
  isDataForPlaybackReady: boolean;
  convergedGPSPoint?: GPSPoint;
  /** In radians */
  convergedGlobalYawNav?: number;
  gpsTracks?: AnimationTracks;
  gpsShadowTracks?: AnimationTracks;
  navTracks?: AnimationTracks;
  navShadowTracks?: AnimationTracks;
  flightInfo?: FlightInfo;
  cameraDollyFollowData?: CameraDollyFollowData;
  airTrafficData: {
    updatedAt: number;
    rawMessages: { [icaoAddress: string]: AirTrafficItemState };
    localTraffic: LocalAirTrafficItemState[];
    displaySettings: AirTrafficDisplaySettings;
  };
  adsbStationState: AdsbStationState | null;
  mapCaptureTrajectory: LngLatAltTuple[];
  mapCaptureCompletedTrajectory: LngLatAltTuple[];
  mapSearchPinPosition?: Pick<GpsWaypoint.AsObject, "latitude" | "longitude">;
  recordAdsbData: boolean;
}

export const initialState: FlightPlayerState = {
  playing: false,
  playbackTime: 0,
  seekTime: 0,
  // This flag keeps track of whether the last time update action was a seek action
  hasSeeked: false,
  playbackRate: 1.0,
  videoError: undefined,
  muted: false,
  fullscreenState: {
    isFullscreen: false,
    fullscreenTarget: null,
    fullscreenTargetPriority: 0,
  },
  panes: null, // null means use default panes (default is different based on a feature flag, see ./selectors)
  duration: 0,
  mapStyle: MapStyle.SATELLITE,
  telemetry: {
    pose: { all: [] },
    battery: { all: [] },
    vehicleStatus: { all: [] },
    gps_data: { all: [] },
    gimbalNavTransform: { all: [] },
    userCameraStatus: { all: [] },
    userCameraStatusNG: { all: [] },
    vehicleAndCameraPoseLite: { all: [] },
    rtkGpsData: { all: [] },
  },
  isDataForPlaybackReady: false,
  trajectoryTypes: uniq(["gps"]),
  recordingStartTime: 0,
  cameraDollyFollowData: undefined,
  airTrafficData: {
    updatedAt: Date.now(),
    rawMessages: {},
    localTraffic: [],
    displaySettings: {
      filter: { radius: nauticalMilesToMeters(6), altitude: feetToMeters(3000) },
      alert: { radius: nauticalMilesToMeters(1.2), altitude: feetToMeters(1000) },
    },
  },
  mapCaptureTrajectory: [],
  mapCaptureCompletedTrajectory: [],
  adsbStationState: null,
  recordAdsbData: false,
};

export type TelemetryKey = keyof FlightPlayerState["telemetry"];
export type TelemetryValue<K extends TelemetryKey> =
  FlightPlayerState["telemetry"][K] extends TelemetryData<infer T> ? T : unknown;
type GenericCurrentTelemetryPayload<Key extends TelemetryKey, Value = TelemetryValue<Key>> = {
  key: Key;
  msg: Value;
};
type GenericTelemetryHistoryPayload<Key extends TelemetryKey, Value = TelemetryValue<Key>> = {
  key: Key;
  msgs: Value[];
};
// Dynamically create a discriminated union type for possible telemetry payloads
type DistributeCurrentTelemetry<T> = T extends TelemetryKey
  ? GenericCurrentTelemetryPayload<T>
  : unknown;
type DistributeTelemetryHistory<T> = T extends TelemetryKey
  ? GenericTelemetryHistoryPayload<T>
  : unknown;
type CurrentTelemetryPayload = DistributeCurrentTelemetry<TelemetryKey>;
type TelemetryHistoryPayload = DistributeTelemetryHistory<TelemetryKey>;

const { reducer, actions } = createSlice({
  name: "flightPlayer",
  initialState,
  reducers: {
    togglePlay(state) {
      if (state.videoError !== undefined) return;

      state.playing = !state.playing;
      if (state.playbackTime >= state.duration) {
        state.seekTime = 0;
        state.hasSeeked = true;
      }
    },
    setIsPlaying(state, action: PayloadAction<boolean>) {
      if (state.videoError !== undefined) return;

      state.playing = action.payload;
      if (state.playbackTime >= state.duration) {
        state.seekTime = 0;
        state.hasSeeked = true;
      }
    },
    updateTime(state, action: PayloadAction<number>) {
      if (state.videoError !== undefined) return;

      // If playback appears to be going back in time but we haven't seeked, we can assume the
      // video element was reset. Instead of accepting the video player's update to an earlier
      // time, we want to force it to resume from our last known playback time.
      if (action.payload < state.playbackTime && !state.hasSeeked) {
        // Set the seek time ourselves, which will update the player element
        state.seekTime = state.playbackTime;
      } else if (action.payload >= state.duration) {
        state.playing = false;
        state.playbackTime = state.duration;
      } else {
        state.playbackTime = action.payload;
      }
      state.hasSeeked = false;
    },
    setErrorFromVideo(
      state,
      action: PayloadAction<SyntheticEvent<HTMLVideoElement, Event> | undefined>
    ) {
      state.videoError = action.payload?.nativeEvent ?? undefined;
      state.playing = false;
    },
    updateDuration(state, action: PayloadAction<number>) {
      state.duration = action.payload;
    },
    setFullscreen(state, action: PayloadAction<boolean>) {
      state.fullscreenState.isFullscreen = action.payload;
    },
    setFullscreenTarget(state, action: PayloadAction<{ target: string; priority: number }>) {
      if (action.payload.priority > state.fullscreenState.fullscreenTargetPriority) {
        state.fullscreenState.fullscreenTarget = action.payload.target;
        state.fullscreenState.fullscreenTargetPriority = action.payload.priority;
      }
    },
    clearFullscreenTarget(state, action: PayloadAction<string>) {
      if (action.payload === state.fullscreenState.fullscreenTarget) {
        state.fullscreenState.fullscreenTarget = null;
        state.fullscreenState.fullscreenTargetPriority = 0;
      }
    },
    seekToTime(state, action: PayloadAction<number>) {
      state.seekTime = action.payload;
      state.hasSeeked = true;
    },
    setPanes(state, action: PayloadAction<PaneType[]>) {
      state.panes = action.payload;
    },
    setPlaybackRate(state, action: PayloadAction<number>) {
      state.playbackRate = action.payload;
    },
    setMapViewport(state, action: PayloadAction<ViewportProps>) {
      state.mapViewport = action.payload;
    },
    updateMapViewport(state, action: PayloadAction<Partial<ViewportProps>>) {
      state.mapViewport = Object.assign({}, state.mapViewport, action.payload);
    },
    toggleMapStyle(state) {
      state.mapStyle = getNextMapStyle(state.mapStyle);
    },
    setIsDataForPlaybackReady(state, action: PayloadAction<boolean>) {
      state.isDataForPlaybackReady = action.payload;
    },
    setConvergedGPSPoint(state, action: PayloadAction<GPSPoint | undefined>) {
      state.convergedGPSPoint = action.payload;
    },
    setGlobalYawNav(state, action: PayloadAction<number | undefined>) {
      state.convergedGlobalYawNav = action.payload;
    },
    setFlightInfo(state, action: PayloadAction<FlightInfo | undefined>) {
      state.flightInfo = action.payload;
    },
    setRecordingStartTime(state, action: PayloadAction<number>) {
      state.recordingStartTime = action.payload;
    },
    setGPSTracks(state, action: PayloadAction<(typeof state)["gpsTracks"]>) {
      state.gpsTracks = action.payload;
    },
    setGPSShadowTracks(state, action: PayloadAction<(typeof state)["gpsShadowTracks"]>) {
      state.gpsShadowTracks = action.payload;
    },
    setNAVTracks(state, action: PayloadAction<(typeof state)["navTracks"]>) {
      state.navTracks = action.payload;
    },
    setNAVShadowTracks(state, action: PayloadAction<(typeof state)["navShadowTracks"]>) {
      state.navShadowTracks = action.payload;
    },
    setCameraDollyFollowData(state, action: PayloadAction<CameraDollyFollowData | undefined>) {
      state.cameraDollyFollowData = action.payload;
    },
    setCurrentTelemetryData(state, { payload }: PayloadAction<CurrentTelemetryPayload>) {
      const { key, msg } = payload;

      const telemetry = state.telemetry[key];
      if (telemetry && msg) {
        telemetry.current = telemetry.current?.utime !== msg.utime ? msg : telemetry.current;
      }
      if (key === "vehicleAndCameraPoseLite" || key === "vehicleStatus") {
        // Vehicle position might have been updated, update local air traffic if it has not been updated since in AIR_TRAFFIC_MIN_UPDATE_TIME
        if (Date.now() - state.airTrafficData.updatedAt > AIR_TRAFFIC_MIN_UPDATE_TIME) {
          state.airTrafficData.updatedAt = Date.now();
          state.airTrafficData.localTraffic = getLocalAirTraffic({
            airTrafficData: state.airTrafficData,
            vehicleAndCameraPoseLite: state.telemetry.vehicleAndCameraPoseLite,
            vehicleStatus: state.telemetry.vehicleStatus,
            trafficDisplaySettings: state.airTrafficData.displaySettings,
          });
        }
      }
    },
    setTelemetryDataHistory(state, { payload }: PayloadAction<TelemetryHistoryPayload>) {
      const { key, msgs } = payload;
      const telemetry = state.telemetry[key];

      telemetry.all = msgs;
      if (!telemetry.current) {
        telemetry.current = msgs[0];
      }
      state.duration = msgs.length > 0 ? msgs[msgs.length - 1].utime - msgs[0].utime : 0;
    },
    handleAdsbTrafficMessage,
    handleMapCaptureMessage,
    handlePingStationStatusMessage,
    setAirTrafficDisplaySettings(
      state,
      { payload }: { payload: Partial<AirTrafficDisplaySettings> }
    ) {
      state.airTrafficData.displaySettings = {
        ...state.airTrafficData.displaySettings,
        ...payload,
      };
      // Display settings have been updated, update local air traffic
      state.airTrafficData.localTraffic = getLocalAirTraffic({
        airTrafficData: state.airTrafficData,
        vehicleAndCameraPoseLite: state.telemetry.vehicleAndCameraPoseLite,
        vehicleStatus: state.telemetry.vehicleStatus,
        trafficDisplaySettings: state.airTrafficData.displaySettings,
      });
      // Set update time to current time
      state.airTrafficData.updatedAt = Date.now();
    },
    setMapSearchPinPosition(
      state,
      action: PayloadAction<Pick<GpsWaypoint.AsObject, "latitude" | "longitude"> | undefined>
    ) {
      state.mapSearchPinPosition = action.payload;
    },
    setRecordAdsbData(state, action: PayloadAction<boolean>) {
      state.recordAdsbData = action.payload;
    },
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state, { payload }: LocationChangeAction) => {
      // Want to reset if path changes, but not query parameters
      if (state.currentPathname !== payload.location.pathname) {
        return {
          ...initialState,
          // Preserve these settings across flights
          panes: state.panes,
          mapStyle: state.mapStyle,
          currentPathname: payload.location.pathname,
        };
      }
      state.currentPathname = payload.location.pathname;
    },
  },
});

export default reducer;
export { actions as flightPlayerActions };
